<script>
import BVForm from "@/components/BVForm/BVForm.vue";

export default {
  name: "BVForms",
  props: {
    baseSchema: { type: Array, default: () => [] },
    callBackInit: { type: Function, default: () => {} },
    title: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    callBackSubmit: { type: Function, default: () => {} },
    oneDefault: { type: Boolean, default: false },
    observer: { type: Array, default: () => [] },
  },
  components: {
    BVForm,
  },
  data() {
    return {
      forms: [],
      containerId: this.title.replace(/\s/g, ""),
      data: [],
      loader: false,
    };
  },
  mounted() {
    if (this.oneDefault) {
      console.log(this.oneDefault);
      this.pushNewForm();
    }

    this.callBackInit(this);
  },
  methods: {
    pushNewForm() {
      this.forms.push([...JSON.parse(JSON.stringify(this.baseSchema))]);
    },
    isObserver(name) {
      return this.observer.includes(name);
    },
    watcher(item, indexList) {
      // console.log( item, indexList);
      if (this.isObserver(item.name)) {
        const data = { ...item, indexList, forms: this.forms };
        // console.log( data);
        this.$emit("watcher", data);
      }
    },
    async saveForms() {
      let container = document.querySelector(`#${this.containerId}`);
      let inputs = container.querySelectorAll("input, select");

      try {
        inputs.forEach((input) => {
          if (!input.checkValidity()) {
            input.reportValidity();
            throw new Error("Formulario incompleto");
          }
        });

        this.data = this.forms.map((element) => {
          let formData = {};
          element.forEach((item) => {
            formData[item.name] = item.value;
          });
          return formData;
        });

        await this.callBackSubmit(this);
      } catch (error) {
        console.log(error);
        //callbackDeError?
      }
    },
  },
};
</script>
<template>
  <div :id="containerId">
    <h3 class="mt-2">
      <strong>
        {{ title }}
      </strong>
    </h3>
    <div v-for="(schema, idx) in forms" :key="title + idx">
      <BVForm
        :observer="observer"
        @watcher="(subData) => watcher(subData, idx)"
        :schema="schema"
        :disabled="disabled"
        hide-footer
      >
        <template #title>
          <div class="d-flex justify-content-between pt-3">
            <h4 class="text-white-50">
              <strong>{{ idx + 1 }}. {{ title }}</strong>
            </h4>
            <button
              type="button"
              class="btn btn-outline-danger my-2"
              @click.prevent="forms.splice(idx, 1)"
              :disabled="loader || disabled"
            >
              <i class="fas fa-solid fa-trash px-1"></i>Eliminar
            </button>
          </div>
        </template>
      </BVForm>
    </div>
    <div class="d-flex flex-row-reverse py-2" style="width: 100%; gap: 5px">
      <button
        class="btn px-4 py-2 btn-primary"
        @click="saveForms"
        :disabled="loader || disabled"
      >
        <span v-if="loader"><i class="fas fa-spinner fa-spin"></i></span>
        <span v-else>
          <slot name="text-guardar"> Guardar </slot>
        </span>
      </button>
      <button
        class="btn px-4 py-2 btn-success"
        @click="pushNewForm"
        :disabled="loader || disabled"
      >
        <span>
          <slot name="text-nuevo-registro">
            <i class="fas fa-plus-circle"></i> Nuevo registro
          </slot>
        </span>
      </button>
    </div>
  </div>
</template>

<style>
button span {
  color: #fff;
}
</style>
